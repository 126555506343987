import type {
  ConditionsField,
  Rules,
  RadioOption,
} from '~/components/BaseForm/types'

type InputRadio = {
  inputType: 'radio-group'
  containerInputClass?: string
  conditions?: ConditionsField
  hidden?: boolean
  options: RadioOption[]
  viewModeRow?: boolean
  order?: number
  attr: {
    inputHtml?: string
    labelHtml?: string
    labelTranslateKey?: string
    name: string
    placeholderTranslateKey?: string
    rules?: Rules
    vertical: boolean
  }
}

const inputRadio: InputRadio = {
  inputType: 'radio-group',
  attr: {
    vertical: false,
    name: 'radio',
  },
  options: [],
}

export default inputRadio
